import Vue from "vue";
import { API } from "../../../services/API"

function initialState() {
  return {
    dados: [],
    acao: "C", // E=Edicao, I=Inclusão, C=Consulta
    deposito_edicao: {},  // Deposito Selecionad para Edição
    deposito_selecionado: {}, 

    currentPage: 1,
    count_dados: null,

    // Filtro na Tela de Consulta 
    filtro: {
      cod_deposito: null,
      nome_deposito: null,
      cep: null,
      logradouro: null,
      numero: null,
      bairro: null,
      cidade: null,
      uf: null,
      complemento: null,
      quadra: null,
      lote: null,
      tipo: null,
    },
    // Abas do container 
    abas: [
      ['mdi-text-box-search-outline', 'Dados', '0'],
      ['mdi-archive-outline', 'Estoque', '0'],
      ['mdi-timer-sand-complete', 'Movimento', '0'],
      
    ],
  }
}

var vm_store_Deposito = new Vue({

  data() {
    return initialState();
  },

  methods: {
    resetData() {
      Object.assign(this.$data, initialState());
    },

    // Métodos da Obra - Gets / Post / Put / Delete
    // Método - Get All
    async DepositosGet(p_params) {
      const resposta = await API.get("/obras/depositos", { params: { ...p_params } });
      if (resposta) {
        this.dados = [];
        if (resposta.data.result != 'Não possui dados') {
          this.dados = resposta.data.result
          this.count_dados = resposta.data.result.length;
          return resposta.data.result
        }
      }
    },
    // Método - Get Individual
    async DepositoGet(p_params) {
      const resp = await API.get(`/obras/deposito/`, { params: { ...p_params } });
      return resp.data.result
    },
    // Método - Post 
    async DepositoPost(body) {
      const resp = await API.post(`/obras/depositos`, JSON.stringify(body));
      return resp;
    },
    // Método - Put
    async DepositoPut(p_params) {
      const resp = await API.put(`/obras/depositos`, { params: { ...p_params }});
      return resp;
    },
    // Método - Delete
    async DepositoDelete(p_params) {
      const resp = await API.delete(`/obras/depositos`, { params: { ...p_params }});
      console.log("GetDelete", resp)
      return resp;
    },

    async InsumosGet(p_params) {
      const resposta = await API.get("/insumos", { params: { ...p_params } });
      if (resposta) {
        this.insumos = resposta.data.result;
        return resposta.data.result;
      }
    }
  },
  
  watch: {
    // docDocumentos()           { this.atualiza_abas() },
    // docContrato()             { this.atualiza_abas() },
    // docTransferencia()        { this.atualiza_abas() },
    // docRenogociacao()         { this.atualiza_abas() },
    // docRescisao()             { this.atualiza_abas() },
    // docAutorizacaoEscritura() { this.atualiza_abas() },
    // docEscritura()            { this.atualiza_abas() },
    // docMensagens()            { this.atualiza_abas() }
  },
})

export default vm_store_Deposito
