<template>
  <v-container fluid id="DepositoConsulta" class="pa-0">
    <!-- Cabeçalho do Conteudo -->
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="headline lighten-2"
    >
      <!-- Título da página -->
      <v-toolbar-title class="text-white title-page mt-2 ml-4">
        Deposito
      </v-toolbar-title>

      <!-- Botões - Filtro / Novo / Opções -->
      <v-toolbar-title class="mt-0 mb-6 mr-4 ml-4 pa-0" dark elevation="0">
        <div class="d-flex" tabindex="-1">
          <v-container
            class="pa-0 ma-0 mb-7 d-flex"
            style="margin-top: -4px; max-width: 1200px"
          >
            <!-- Input Filtro - Chips / Formulario -->
            <v-menu
              id="vMenu"
              ref="vMenu"
              v-model="menu"
              bottom
              right
              fluid
              offset-y
              origin="top"
              class="pa-0 d-flex flex-wrap"
              elevation="0"
              :close-on-content-click="false"
              @input="abriuConsulta"
            >
              <template v-slot:activator="{ on }">
                <!-- Input do Filtro -->
                <div
                  ref="vMenuDiv"
                  class="false-filter d-flex align-center"
                  align="center"
                  v-on="on"
                >
                  <v-icon class="false-icon ml-3 mr-2" color="#FFF">
                    mdi-magnify
                  </v-icon>

                  <!-- Div de todos os chips selecionados -->
                  <div class="false-text flex-wrap">
                    <!-- Chip Codigo-->
                    <v-chip
                      v-if="store_Deposito.filtro.cod_deposito"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Codigo:&nbsp;
                      </span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Deposito.filtro.cod_deposito
                      }}</strong>
                    </v-chip>
                    <!-- Chip Nome -->
                    <v-chip
                      v-if="store_Deposito.filtro.nome_deposito"
                      style="max-width: 250px"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Nome do Deposito:&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Deposito.filtro.nome_deposito
                      }}</strong>
                    </v-chip>
                    <!-- Chip CEP -->
                    <v-chip
                      v-if="store_Deposito.filtro.cep"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >CEP:&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Deposito.filtro.cep
                      }}</strong>
                    </v-chip>
                    <!-- Chip Endereco -->
                    <v-chip
                      v-if="store_Deposito.filtro.endereco_dep"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Endereço:&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Deposito.filtro.endereco_dep
                      }}</strong>
                    </v-chip>
                    <!-- Chip Quadra -->
                    <v-chip
                      v-if="store_Deposito.filtro.quadra"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Quadra:&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Deposito.filtro.quadra
                      }}</strong>
                    </v-chip>
                    <!-- Chip Lote -->
                    <v-chip
                      v-if="store_Deposito.filtro.lote"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Lote:&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Deposito.filtro.lote
                      }}</strong>
                    </v-chip>
                    <!-- Chip Sitacao -->
                    <v-chip
                      v-if="store_Deposito.filtro.situacao"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Situação:&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Deposito.filtro.situacao
                      }}</strong>
                    </v-chip>
                  </div>

                  <v-icon class="false-icon ml-2 mr-4" color="#FFF">
                    mdi-filter-variant-plus
                  </v-icon>
                </div>
              </template>
              <!-- Formulario do Filtro -->
              <v-container
                :style="{ 'background-color': COR_SECUNDARIA_2 }"
                style="
                  min-width: 800px !important;
                  max-width: 1200px !important;
                  margin-bottom: -34px;
                "
              >
                <!-- 1ª Linha do Formulario -->
                <v-row no-gutters class="linhas mb-n2">
                  <v-col md="4">
                    <!-- Input Código -->
                    <v-text-field
                      id="txtCodigos"
                      ref="txtCodigo"
                      label="Código"
                      placeholder="Código"
                      dense
                      v-model="filtro_local.cod_deposito"
                      cache-items
                      flat
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="100"
                      clearable
                      tabindex="0"
                      class="mr-2"
                      autofocus
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Input Nome Deposito -->
                  <v-col md="8">
                    <v-text-field
                      v-model="filtro_local.nome_deposito"
                      item-text="nome_deposito"
                      item-value="nome_deposito"
                      label="Nome do Deposito"
                      placeholder="Nome do Deposito"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="60"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- 2ª Linha do Formulario -->
                <!-- Input numero -->
                <v-row no-gutters class="linhas mb-n2">
                    <!-- Input cep -->
                  <v-col md="2">
                    <v-text-field
                      v-model="filtro_local.cep"
                      item-text="cep"
                      item-value="cep"
                      label="CEP"
                      placeholder="CEP"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="9"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Input Endereço -->
                  <v-col md="6">
                    <v-text-field
                      v-model="filtro_local.endereco_dep"
                      item-text="endereco_dep"
                      item-value="endereco_dep"
                      label="Endereço"
                      placeholder="Endereço"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="20"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Input Quadra -->
                  <v-col md="2">
                    <v-text-field
                      v-model="filtro_local.quadra"
                      item-text="quadra"
                      item-value="quadra"
                      label="Quadra"
                      placeholder="Quadra"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="60"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Input Lote -->
                  <v-col md="2">
                   <v-text-field
                      v-model="filtro_local.lote"
                      item-text="lote"
                      item-value="lote"
                      label="Lote"
                      placeholder="Lote"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="60"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- 3ª Linha do Formulario -->
                <v-row no-gutters class="linhas">
                  <!-- input Situação -->
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.situacao"
                      item-text="situacao"
                      item-value="situacao"
                      label="Situação"
                      placeholder="Situação"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="60"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <!-- Fim Campos Filtro -->
              <!-- Cria uma linha de divisoria -->
              <v-divider></v-divider>

              <!-- Container Botões - Limpar / Consultar -->
              <div
                class="pa-4 d-flex justify-space-between"
                v-bind:style="{ 'background-color': COR_SECUNDARIA }"
              >
                <!-- Botão Limpar Todos -->
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  outlined
                  tabindex="-1"
                  :small="isMobile"
                  @click="limpa_todos()"
                >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2">
                    mdi-close
                  </v-icon>
                  <span>Limpar Todos</span>
                </v-btn>

                <!-- Botao Consultar -->
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  tabindex="-1"
                  :small="isMobile"
                  @click="busca(1)"
                >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2">
                    mdi-magnify
                  </v-icon>
                  <span>Consultar</span>
                </v-btn>
              </div>
            </v-menu>
          </v-container>
          <!-- Espaçamento entre os elementos -->
          <v-spacer></v-spacer>

          <!-- Botão Novo --------------------------------->
          <router-link
            :to="{ name: 'DepositoContainer' }"
            class="nav-link"
            aria-current="page"
            tabindex="-1"
          >
            <v-btn
              class="text-white text-capitalize"
              :color="COR_PRINCIPAL"
              elevation="0"
              :small="isMobile"
            >
              <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
              <span>Novo</span>
            </v-btn>
          </router-link>
        </div>
      </v-toolbar-title>
      <!-- Fim Botões de ação cabeçalho -->
    </v-toolbar-title>

    <!-- Tabela -->
    <v-row class="mt-n11 mx-0">
      <!-- Grade  -->
      <v-col cols="12" class="pa-0 mx-0">
        <v-container
          class="container-principal container-rounded rounded-lg mx-0 px-0"
        >
          <!-- Conteiner -------------------------------------- -->
          <v-data-table
            id="virtualScrollTable"
            ref="virtualScrollTable"
            :items="store_Deposito.dados"
            :headers="headers"
            :loading="loading"
            :height="tableHeight"
            :items-per-page="20"
            :hide-default-footer="true"
            :hide-default-header="isMobile"
            :page="currentPage || 1"
            :server-items-length="store_Deposito.count_dados"
            fixed-header
            dense
            style="white-space: nowrap"
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado"
            class="data-table"
          >
            <!-- @update:page="$vuetify.goTo($refs.virtualScrollTable)" -->
            <template #item="{ item } ">
              <tr v-if="!isMobile">
                <!-- Botão de Ações -->
                <td style="width: 0.1%">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="grey"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <!-- Lista de Ações -->
                    <v-list class="py-0">
                      <v-list-item
                        v-for="(opcao, i) in items"
                        :key="i"
                        @click="() => {}"
                        class="px-2"
                      >
                        <!-- Ação de Editar -->
                        <v-list-item-title v-if="opcao.title == 'Editar'">
                          <router-link
                            :to="'/depositocontainer/' + item.cod_deposito"
                            class="nav-link d-flex justifi-center align-center mt-2 ml-1"
                            aria-current="page"
                            style="white-space: normal"
                          >
                            <v-icon color="green" class="mr-2 icon-menu">
                              mdi-pencil
                            </v-icon>
                            {{ opcao.title }}
                          </router-link>
                        </v-list-item-title>

                        <!-- Ação de Excluir -->
                        <v-list-item-title
                          v-else-if="opcao.title == 'Excluir'"
                          @click="ModalConfirmacao(item)"
                        >
                          <v-icon color="red" class="mr-2 icon-menu">
                            mdi-delete
                          </v-icon>
                          {{ opcao.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>

                <!-- Campo cod_deposito -->
                <td style="width: 2%">
                  {{ item.cod_deposito }}
                </td>

                <!-- Campo obra/numero/logradouro -->
                <td style="width: 3%">
                  <router-link
                    :to="'/depositocontainer/' + item.cod_deposito"
                    class="nav-link d-flex flex-column mt-2"
                    aria-current="page"
                  >
                    <span
                      class="d-flex align-center mb-1"
                      style="font-size: 14px; font-weight: bold"
                      :style="{ color: COR_SUBTITULO }"
                    >
                      {{ item.nome_deposito }}
                    </span>
                    <span
                      style="font-size: 12px; color: #808080 !important"
                      class="mb-1"
                    >
                    <span style="font-weight: bold">Endereço: </span>
                      {{ item.endereco_dep }}
                    </span>
                  </router-link>
                </td>
                <!-- Campo uf-->
                <td
                  style="width: 3%; font-size: 12px; color: #808080 !important"
                >
                  {{item.uf }}
                </td>

                <!-- Campo Situacao -->
                <td
                  style="width: 3%; font-size: 12px; color: #808080 !important"
                >
                  {{item.situacao}}
                </td>
                
              </tr>
            </template>
          </v-data-table>
        </v-container>
      </v-col>
      <!-- Fim Grade --------------------------------- -->
    </v-row>

    <!-- dialog -->

    <!-- Paginacao -->
    <div
      class="paginacao d-flex justify-end align-center"
      style="background-color: #ffffff"
    >
      <span style="color: grey; font-size: 14px" class="mr-3"
        >{{ store_Deposito.count_dados }} linhas</span
      >
      <v-pagination
        id="pagination"
        ref="pagination"
        v-model="currentPage"
        :length="paginas"
        total-visible="5"
        @input="handlePageChange"
        aria-controls="dataTable"
        class="mt-n1"
        style="color: grey"
      />
    </div>

    <!-- Dialog Confirmação de Exclusão -->
    <v-container>
      <v-dialog
        v-model="dialogCancelar"
        transition="dialog-bottom-transition"
        max-width="410"
        class="pa-0"
      >
        <v-card elevation="0" class="">
          <v-card-title
            v-bind:style="{ 'background-color': COR_PRINCIPAL }"
            class="justify-space-between py-2 px-3"
          >
            <span class="text-white title-page body-1"></span>
            <v-btn @click="dialogCancelar = false" icon dark color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-title class="text-h6 custom-card-title">
            <p>Deseja realmente excluir?</p>
            <p>
              Atenção: Todos os dados serão permanentemente apagados. Essa ação
              não pode ser desfeita.
            </p>
            <p>Por favor, confirme se deseja prosseguir.</p>
          </v-card-title>
          <v-card-text>
            <v-container class="mt-0 px-0">
              <v-row>
                <v-col class="pb-0" cols="12">
                  <v-form ref="form" v-model="valid">
                    <v-textarea
                      rows="1"
                      auto-grow
                      counter="200"
                      v-model="container.message"
                      :rules="[rules.required]"
                      class="input-razao mb-0"
                      loader-height="1"
                      autofocus
                      background-color="#FFF"
                      label="Motivo da exclusão:"
                      placeholder="Motivo da exclusão:"
                      light
                      filled
                      outlined
                      required
                      clearable
                      dense
                    ></v-textarea>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn
              id="btn_cancelar"
              ref="btn_cancelar"
              class="mr-4"
              color="primary"
              text
              @click="dialogCancelar = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :disabled="!valid"
              :loading="loading"
              @click="deletaDeposito()"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import store_usuario from "../../../store/store_usuario";
import store_Deposito from "./store_Deposito";
import {
  formatNumber,
  formatDate,
  nomeIniciais,
} from "../../../services/funcoes";
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
  COR_SECUNDARIA_2,
} from "../../../services/constantes";

// import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
// // import { API } from "../../../services/API";
// import VuetifyMoney from "@/components/ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";

export default {
  name: "DepositoConsulta",
  components: {
  },

  data() {
    return {
      store_usuario: store_usuario,
      store_Deposito: store_Deposito,
      formatDate: formatDate,
      formatNumber: formatNumber,

      loading: false,
      search: null,
      valid: false,
      search_input_focus: false,
      menu: false,
      loading_excluir: false,
      filtro: null,
      currentPage: 1,

      /* Container  */
      container: {
        //receipt: "",
        message: "",
      },

      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SECUNDARIA: COR_SECUNDARIA,
      COR_SECUNDARIA_2: COR_SECUNDARIA_2,
      COR_SUBTITULO: COR_SUBTITULO,
      dialogCancelar: false,
      count_dados: null,
      paginas: null,

      situacao: ["Finalizada", "Em Andamento", "Parada"],

      filtro_local: {
        // Filtro na Tela de Consulta --------
        cod_deposito: null,
        logradouro: null,
        nome_deposito: null,
        cep: null,
        logradouro: null,
        numero: null,
        bairro: null,
        cidade: null,
        uf: null,
        complemento: null,
        bairro: null,
        cidade: null,
        tipo: null,
        endereco_dep: null,
      },

      data_picker: {
        "min-width": "150px !important",
      },

      rules: {
        required: (v) => !!v || "Preencha o motivo para continuar",
      },

      /* Cabeçalho da Tabela */
      headers: [
        { text: "", value: "", sortable: false },
        { text: "Código", value: "cod_deposito", sortable: true },
        { text: "Nome Deposito", value: "nome_deposito", sortable: true },
        { text: "UF", value: "uf", sortable: true },
        { text: "Situação", value: "situacao", sortable: true },
      ],

      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],
    };
  },

  async created() {
    this.busca();
  },

  async mounted() {
    this.store_Deposito.deposito_edicao = {};
  },

  watch: {
    search(val) {
      // console.log('WATCH', val)
      this.currentPage = 1;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },

    tableHeight() {
      if (this.isMobile) return window.innerHeight - 149 + 30 - 90;
      else return window.innerHeight - 149 - 55;
    },
  },

  methods: {
    ModalConfirmacao(item) {
      this.store_Deposito.deposito_selecionado = item;
      this.dialogCancelar = true;
    },
  async deletaDeposito() {
    var lo_params = { cod_deposito: store_Deposito.deposito_selecionado.cod_deposito };
    const lo_Res = await this.store_Deposito.DepositoDelete(lo_params);
    this.dialogCancelar = false;
    this.busca();
  return lo_Res;
},

    handlePageChange(value) {
      this.currentPage = value;
    },

    limpa_todos() {
      for (let prob in this.filtro_local) {
        //console.log('limpando: ' , this.filtro_local[prob])
        this.filtro_local[prob] = null;
      }
      this.filtro_local.lote = ``;
      this.store_Deposito.filtro = {};
    },


    async busca() {
      this.loading = true;
      this.menu = false;
      //Opções de busca do Filtro
    
      this.store_Deposito.filtro = { ...this.filtro_local };
      var lo_params = { ...this.store_Deposito.filtro };

      let lo_Res 
      lo_Res = await this.store_Deposito.DepositosGet(lo_params);
      this.loading = false;
    },

    abriuConsulta(val) {
      // console.log( 'abriuConsulta', this.dados_editar)
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = { ...this.store_Deposito.filtro };
        });
      }
    },

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },
  },
};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif !important;
}

.icon-filter {
  font-size: 20px;
}

.false-filter {
  background-color: #6c87a2;
  height: 38px !important;
  min-width: 400px !important;
  /* max-width: 900px!important; */
  border-radius: 5px;
  justify-content: space-between;
}

.false-text {
  width: calc(100%);
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  color: #C9CBCA;
  cursor: pointer;
  overflow-x: hidden;
}

table tr {
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.width-adress {
  display: block;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

.customer {
  display: block;
  font-weight: 600;
}

.email {
  font-family: "Open Sans", sans-serif !important;
  color: #505050;
  opacity: 0.8;
}

.custom-card-title p {
  word-break: break-word; /* Evita que palavras sejam quebradas de forma estranha */
  white-space: normal; /* Garante que o texto quebre linhas naturalmente */
}

.v-btn {
  display: inline-block;
}

.v-data-table-header-mobile {
  background-color: orangered;
  display: none !important;
}

.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.search-input,
.search-input-longo {
  min-width: 400px;
  max-width: 400px;
  margin: 0.8rem 0 1.2rem 0;
  height: 35px !important;
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px); */
  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.container-rounded {
  background: #fff !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

#virtual-scroll-table-tablet {
  display: none;
}

@media (max-width: 1024px) {
  .nav-link {
    padding-right: 0px !important;
  }
}

/* CELULAR */
@media (max-width: 599px) {
  .container-principal {
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #fff !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .nav-link {
    padding-left: 0px;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }

  .search-input {
    margin: 0.8rem 0 1.2rem 0;
    min-width: auto;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }

  .container-principal {
    max-width: 100% !important;
  }
}

.v-menu__content {
  z-index: 10 !important;
}

/* Verificar com o Fernando */
.cor-subtitulo {
  color: var(--COR_SUBTITULO);
}
</style>
